import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Tags from '../components/tags'
import SEO from '../components/seo'
import './blog.css'

const BlogPage = ({data}) => (
        <Layout>
            <header style={{
              width: `100%`,
              textAlign: `center`,
              background: `linear-gradient(90deg, rgb(193, 232, 251) 25%, rgb(237, 243, 255) 75%)`,
              minHeight: `260px`,
              display: `flex`,
              flexDirection: `column`,
              justifyContent: `center`,
              position: `relative`
            }}>
              <div>
                <h1 style={{margin: 0, textTransform: `uppercase`}}>Blog</h1>
                <div style={{color: `#737373`, fontSize: 14}}>Just a space to write my stuff down.</div>
              </div>
            </header>
            <div className="container">
              <Tags />
              {data.allMarkdownRemark.nodes.map((node, index) => (
              <Link 
                to={`blog/${node.fields.slug}`}
                style={{
                  textDecoration: `none`,
                  color: `inherit`
                }}
                key={`post-${index}`}
              >
                <div className="blog-post">
                  <h2>{node.frontmatter.title}</h2>
                  <span className="blog-post-date">{node.frontmatter.date}</span>
                  <div className="blog-post-excerpt">
                      {node.excerpt}
                  </div>
                </div>
              </Link>
              ))}
            </div>
            <SEO title="Blogs" />
        </Layout>
    )

export const query = graphql`
{
  allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, filter: {frontmatter: {status: {eq: "published"}}}) {
    nodes {
      frontmatter {
        title
        date(formatString: "DD MMMM, YYYY")
      }
      html
      excerpt(pruneLength: 500)
      fields {
        slug
      }
    }
  }
}
`

export default BlogPage