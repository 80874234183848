import React from "react"
import './tags.css'

const Tags = ({style}) => (
    <div className="tags" style={style ? style : {}}>
        {/* <span className="tag">Algorithms</span> */}
        {/* <span className="tag">Random</span> */}
    </div>
)

export default Tags